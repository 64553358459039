/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I write software, play Magic: the Gathering, and enjoy the great outdoors. I am a strong believer in accessible software, welcoming communities, and free educational resources.`}</p>
    <p>{`I've worked at a few neat places:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://secondhalf.games"
        }}><strong parentName="a">{`Second Half Games`}</strong></a>{` (2022—current) on a the upcoming game `}<a parentName="li" {...{
          "href": "https://ms80.space"
        }}>{`MEANWHILE IN SECTOR 80`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://uplift.games"
        }}><strong parentName="a">{`Uplift Games`}</strong></a>{` (2020—2021) on the hit game `}<a parentName="li" {...{
          "href": "https://playadopt.me"
        }}>{`Adopt Me!`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.roblox.com"
        }}><strong parentName="a">{`Roblox`}</strong></a>{` (2017—2020) where I created projects like `}<a parentName="li" {...{
          "href": "https://github.com/Roblox/roact"
        }}>{`Roact`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.geckodesigns.com"
        }}><strong parentName="a">{`Gecko Designs`}</strong></a>{` (2015—2017) on pilot websites for large brands like Gillette and Febreze`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://univisioncomputers.com"
        }}><strong parentName="a">{`Univision Computers`}</strong></a>{` (2011—2014) where I got my start doing all sorts of stuff`}</li>
    </ul>
    <p>{`I'm the creator of `}<a parentName="p" {...{
        "href": "https://github.com/rojo-rbx/rojo"
      }}>{`Rojo`}</a>{`, an important tool in the top Roblox developer community. It enables Roblox developers to leverage the large body of traditional software development tools. It has a few related projects like `}<a parentName="p" {...{
        "href": "https://github.com/rojo-rbx/rbx-dom"
      }}>{`rbx-dom`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/rojo-rbx/remodel"
      }}>{`Remodel`}</a>{`.`}</p>
    <p>{`I've been working in the Rust game development ecosystem. I maintain some libraries in that space:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/LPGhatguy/yakui"
        }}>{`yakui`}</a>{`, a declarative UI library aiming to bridge the gap between prototype and production-quality UI`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/LPGhatguy/thunderdome"
        }}>{`Thunderdome`}</a>{`, a generational arena implementation`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/LPGhatguy/crevice"
        }}>{`Crevice`}</a>{`, a library that aids laying out data for use in shaders`}</li>
    </ul>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
