/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const metadata = {
  title: "Wow, Emacs is Cool!"
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p><em parentName="p">{`Disclaimer: I never finished this article while I was still using Emacs, so it's cut a little short.`}</em></p>
    <p>{`I have been writing software since around 2008. I got my start with `}<a parentName="p" {...{
        "href": "https://www.roblox.com/create"
      }}>{`Roblox Studio`}</a>{`, which was a very different piece of software back then. It includes a single-purpose text editor for editing Lua.`}</p>
    <p>{`When it came time to start writing software outside of Roblox, probably beginning in early 2009, I cycled through a bunch of text editors. Notepad++, Scintilla, and Visual Studio 2008 are the big ones I remember. Eventually, I found Sublime Text. To this day, it's one of the only pieces of software I've paid for more than once.`}</p>
    <p>{`My Sublime Text setup varied only a little bit over the years. I built a custom theme or two but eventually reverted to stock. I adopted Vim emulation using Vintage after meeting a curious fellow in my hometown. Later on, that became Vintageous and finally NeoVintageous.`}</p>
    <p>{`Notably, I never really wrote any of my own extensions. My editor customization was only skin deep, and I thought that was fine.`}</p>
    <p>{`Well, recently I decided to try out Emacs. I started with Spacemacs, and mostly for performance reasons I've switched to Doom Emacs. I'm super impressed with how blurry the line is between configuration and plugins.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
