/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const metadata = {
  title: "The Next Chapter"
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Today, I am announcing my departure from the Roblox ecosystem. I will no longer be maintaining Rojo and other Roblox-related projects. There will be one more release of Rojo sometime in the next few weeks.`}</p>
    <p>{`In the coming months, I'll be launching a new game studio. Stay tuned for more on that in the future.`}</p>
    <p>{`I created Rojo in 2017. It is currently the de facto build tool for Roblox projects and has thousands of users across the platform. I'm proud of creating Rojo. It's been a gateway for Roblox developers to learn traditional software tools as well as a road onto the platform for developers from other ecosystems. Rojo enabled new workflows that weren't possible before.`}</p>
    <p>{`Before I started Rojo, I felt that there was something fundamentally wrong about Roblox's relationship with traditional developer tooling. I had a vision for how I felt people should work on Roblox games and I pushed for it. That vision resonated with some people and put me in direct, very heated conflict with others. Working at Roblox and making a public push towards a specific Roblox developer experience was a bad combination.`}</p>
    <p>{`Rojo ended up being at the center of why Roblox let me go in mid 2020. My relationships with people at Roblox working on Studio were bitter and hostile. I was labeled the "ringleader of engineering dissent." Several of my friends were relocated onto new teams and shown the door. For the damage caused to friends and others, I'm sorry.`}</p>
    <p>{`My participation in Rojo's development since then has been intermittent and has slowed down. There have been a handful of amazing contributors creating features and fixing bugs, but my role has become just showing up every few months to merge PRs and cut a release. I can no longer give Rojo the attention it needs.`}</p>
    <p>{`Rojo was never intended to be a long-term project. When I bought the `}<inlineCode parentName="p">{`rojo.space`}</inlineCode>{` domain, I joked with my friends that I would only register it for two years. At that point, I would either abandon Rojo or it would be built into Roblox. Five years later, there has been no progress towards a Roblox-official version of something like Rojo.`}</p>
    <p>{`This is the right move for me and is overdue. I hope that Rojo serves as a good foundation for the next generation of developers who want to solve the same problems that I did.`}</p>
    <p>{`To everyone who supported me working on Rojo all these years, thank you.`}</p>
    <p>{`See you on the far side.`}<br parentName="p"></br>{`
`}{`Lucien`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
