/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const metadata = {
  title: "Rust C Compiler"
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`During my winter vacation, I decided to start following along
with `}<a parentName="p" {...{
        "href": "https://norasandler.com/2017/11/29/Write-a-Compiler.html"
      }}>{`Nora Sandler's Write a C Compiler series`}</a>{`.`}</p>
    <p>{`I've been interested in compilers for a long time, but besides making small esolangs and partial parsers for existing scripting languages, I've never sat down to build a compiler for a real language end-to-end.`}</p>
    <p>{`I think Rust is a great language to author a compiler in, and it's great practice for developing my intuition with the platform too!`}</p>
    <p>{`You can check out my progress so far, currently up to the end of part one, `}<a parentName="p" {...{
        "href": "https://github.com/LPGhatguy/rust-c-compiler"
      }}>{`on GitHub`}</a>{`.`}</p>
    <p>{`Since the repository won't ever be a `}<em parentName="p">{`useful`}</em>{` project, it exists solely as reference code, whether for other people following along with the series or just for those curious about Rust or compilers`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
