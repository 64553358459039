/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const metadata = {
  title: "Photo with Stan Lee",
  hidden: true
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I got my photo taken with Stan Lee today with my girlfriend,
over at Silicon Valley Comic Con.`}</p>
    <p>{`It was surreal. It felt wrong. I'll explain.`}</p>
    <p>{`The Silicon Valley Comic Con is a very commercial thing. You
spend $50 to get in the door in order to spend more money on
various things related to pop culture.`}</p>
    <p>{`Around a week ago, I had a conversation with my girlfriend about
the con. We noticed that you could have your picture taken with
Stan Lee for around $170 with tax.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"That's a lot of money for a single photo..."`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"He's 95 years old, we might not have another opportunity."`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Alright, let's do it."`}</em></p>
    </blockquote>
    <p>{`The day came. We went to the expo hall, bought some pins,
plushies, and overpriced food. About an hour before the photo-op
began, we shuffled over to the appropriate area to wait.`}</p>
    <p>{`This was the second photoshoot of the day, an hour block devoted
to people willing to spend a fair sum of money to have their
picture taken with a legendary man. Hundreds of people were
gathered, clumped into six groups.`}</p>
    <p>{`The photo-op area was a large ballroom with at least 20 numbered
lines marked in tape across the floor. A row of opaque blue
curtains were set up at the end of the lines. Behind the
curtains were a number of sectioned areas where various
celebrities would sit.`}</p>
    <p>{`The con organizers rallied each group into blocks and corralled
everyone into their appropriate lines before starting the
session.`}</p>
    <p>{`As our group lined up, I noticed the flash umbrellas peeking
over the tops of the curtains.`}</p>
    <p>{`The organizers announced that the photos were starting. I
started counting flashes that popped over the top of the curtain
wall, trying to figure out how long we'd be standing in line.`}</p>
    <p>{`The photographer would shout directions with each photo sequence:`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"Ready!"`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`Flash.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Next!"`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Ready!"`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`Flash.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Next!"`}</em></p>
    </blockquote>
    <p>{`I counted 7 seconds between photos. The groups were moving
quickly.`}</p>
    <p>{`We reached the end of the line and rounded the corner into the
photo area. There was an entourage of security officials, clad
in black suits and sunglasses, just out of frame, ready to help
should Stan need anything.`}</p>
    <p>{`The organizers scanned our QR code that said "yes, we have paid
to meet The Man, Stan Lee."`}</p>
    <p>{`There were just a couple groups ahead of us. Each of them would,
in turn, hustle up to Stan, sitting on a stool, barely awake.
The photographer would shout, a flash would go off, and the
group would continue through yet another wall of curtains.`}</p>
    <p>{`We were next. We hadn't really planned what we were going
to do. We stood on either side of Stan and looked up at the
photographer.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"Ready!"`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Hold!"`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Stan? Stan!"`}</em></p>
    </blockquote>
    <p>{`Everyone stopped. 40 minutes into the photo session, Stan looked
like he was struggling to hold onto consciousness.`}</p>
    <p>{`One of the security people stepped in and leaned to Stan's
ear, nearly shouting:`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"The photographer is trying to get your attention, Stan."`}</em></p>
    </blockquote>
    <p>{`He nodded slightly, as if he had dozed off, then looked up at
the photographer.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`"Ready!"`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`Flash.`}</em></p>
      <p parentName="blockquote"><em parentName="p">{`"Next!"`}</em></p>
    </blockquote>
    <p>{`As we left, I paused a moment to look back at Stan's face, to
see if he'd reveal how he was feeling at that moment. Security
reminded me to keep moving, and shuttled us behind the curtain.`}</p>
    <p>{`An organizer behind a large table called out, "Captain America!"
-- that's us. We received our photo. At the next checkpoint,
our QR code was scanned again, and paired with a barcode on the
egde of the photo.`}</p>
    <p>{`I guess we didn't know what to expect. We both felt
uncomfortable. We went home.`}</p>
    <p>{`The last tendrils of the life of a great man were being sold,
one flash at a time, 7 seconds at a time, $170 at a time.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
