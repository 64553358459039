/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const metadata = {
  title: "New site!",
  hidden: true
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I built a fancy new site with some fun technology:`}</p>
    <ul>
      <li parentName="ul">{`Node.js`}</li>
      <li parentName="ul">{`Nginx as a reverse proxy`}</li>
      <li parentName="ul">{`React server-side rendering`}</li>
    </ul>
    <p>{`I had been exceedingly frustrated waiting for GitHub Pages to support TLS for custom domains. Having a nice CDN with very little setup is nice, but HTTPS is becoming more and more of a hard requirement.`}</p>
    <p>{`All-in-all, I'm impressed with the server-side performance of React 16, and having zero-latency page transitions is rad.`}</p>
    <p>{`The development experience using React and my own templates is also significantly better than Jekyll. I gain hot-reloading and a lot of flexibility in how I construct my pages.`}</p>
    <p>{`The price I pay for this is much more setup and maintenance, as well as hosting costs. I'm confident; we'll see if it pays off.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
