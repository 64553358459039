/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsxRuntime classic */
/* @jsx mdx */

export const metadata = {
  title: "Typography and Markdown Style Test Post",
  hidden: true
};

const layoutProps = {
  metadata
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Heading 2`}</h2>
    <p>{`Body text.`}</p>
    <h3>{`Heading 3`}</h3>
    <p>{`Body text.`}</p>
    <h4>{`Heading 4`}</h4>
    <p>{`Body text.`}</p>
    <h5>{`Heading 5`}</h5>
    <p>{`Body text.`}</p>
    <h6>{`Heading 6`}</h6>
    <p>{`Body text.`}</p>
    <ul>
      <li parentName="ul">{`Unordered list`}</li>
      <li parentName="ul">{`Of stuff`}
        <ul parentName="li">
          <li parentName="ul">{`That is nested!`}
            <ul parentName="li">
              <li parentName="ul">{`Deeply`}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <ol>
      <li parentName="ol">{`Ordered list`}</li>
      <li parentName="ol">{`Of stuff`}
        <ul parentName="li">
          <li parentName="ul">{`That is also nested`}
            <ol parentName="li">
              <li parentName="ol">{`and mixed up!`}</li>
            </ol>
          </li>
        </ul>
      </li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Blockquotes are a cool way to pull in text from other sources.`}</p>
      <p parentName="blockquote">{`Hooray!`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-lua"
      }}>{`print("Hello, from Lua code!")
print("Hello more, from a really long line of Lua code that will likely need to scroll!")

print("Empty line above.")
`}</code></pre>
    <p>{`This is a `}<strong parentName="p">{`sample`}</strong>{` piece of `}<em parentName="p">{`text`}</em>{` with `}<del parentName="p">{`each`}</del>{` most of the `}<inlineCode parentName="p">{`features`}</inlineCode>{` you can have inline in `}<a parentName="p" {...{
        "href": "https://www.markdownguide.org/"
      }}>{`Markdown`}</a>{`.`}</p>
    <p>{`another website?`}</p>
    <p>{`whoa!`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
